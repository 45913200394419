/** @jsx jsx */
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import PropTypes from 'prop-types'
import { Container, jsx, Themed } from 'theme-ui'
import { Image, Layout, SEO, SlicesWrapper } from '../components'
import { EventCards } from '../components/Slices'
import linkResolver from '../utils/linkResolver'

export const query = graphql`
  query TourQuery($uid: String) {
    prismicTour(uid: { eq: $uid }) {
      _previewable
      id
      data {
        meta_description
        meta_title
        og_image {
          url
          alt
        }
        title {
          text
        }
        subtitle {
          text
        }
        image {
          url
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicTourDataBodyText {
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicTourDataBodyQuote {
            slice_type
            primary {
              quote
              description
            }
          }
          ... on PrismicTourDataBodyTextAndImage {
            slice_type
            primary {
              image {
                gatsbyImageData
              }
              image_first
              text {
                raw
              }
            }
          }
          ... on PrismicTourDataBodyImage {
            slice_type
            primary {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
const Page = ({ data, pageContext }) => {
  const doc = data.prismicTour
  if (!doc) return null

  const slices = doc.data.body

  const { now: timestamp } = pageContext

  return (
    <Layout aboveImage>
      <SEO
        title={doc.data.meta_title || doc.data.title.text}
        description={doc.data.meta_description}
        og={{
          image: doc.data.og_image.url,
          alt: doc.data.og_image.alt,
        }}
      />
      <Image
        alt={doc.data.image.alt}
        image={doc.data.image.gatsbyImageData}
        fullWidth
        sx={{ variant: 'overlay', height: [400, 600] }}
      />
      <Container as="section" sx={{ p: 4 }}>
        <Themed.h2 as="h1" sx={{ maxWidth: '20ch' }}>
          <span
            sx={{
              color: 'accent',
              display: 'block',
              fontSize: 2,
              mb: 2,
              textTransform: 'uppercase',
            }}
          >
            {doc.data.title.text}
          </span>
          {doc.data.subtitle.text}
        </Themed.h2>
      </Container>
      <SlicesWrapper slices={slices} />
      <EventCards timestamp={timestamp} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    now: PropTypes.string.isRequired,
  }),
}

Page.query = query

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
