/** @jsx jsx */
import { Link } from 'gatsby'
import {
  componentResolverFromMap,
  withPrismicUnpublishedPreview,
} from 'gatsby-plugin-prismic-previews'
import { jsx, Themed } from 'theme-ui'
import { Layout, Section, SEO } from '../components'
import PageTemplate from '../templates/page'
import TourTemplate from '../templates/tour'
import linkResolver from '../utils/linkResolver'

const NotFoundPage = () => (
  <Layout>
    <SEO title="Seite nicht gefunden" />
    <Section sx={{ textAlign: 'center' }}>
      <Themed.h1>404</Themed.h1>
      <Themed.p sx={{ mx: 'auto', my: 4, maxWidth: '40ch' }}>
        Diese Seite wurde leider nicht gefunden. Hier gehts{' '}
        <Themed.a as={Link} to="/">
          zurück zum Start
        </Themed.a>
        !
      </Themed.p>
    </Section>
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PageTemplate,
      tour: TourTemplate,
    }),
  },
])
