/** @jsx jsx */
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import PropTypes from 'prop-types'
import { jsx, Themed } from 'theme-ui'
import { Image, Layout, Section, SEO, SlicesWrapper } from '../components'
import linkResolver from '../utils/linkResolver'

export const query = graphql`
  query PageQuery($uid: String) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      data {
        meta_description
        meta_title
        og_image {
          url
          alt
        }
        title {
          text
        }
        hero_image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
        body {
          ... on PrismicPageDataBodyText {
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPageDataBodyImage {
            slice_type
            primary {
              image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          ... on PrismicPageDataBodyTextAndImage {
            slice_type
            primary {
              image {
                gatsbyImageData
              }
              image_first
              text {
                raw
              }
            }
          }
          ... on PrismicPageDataBodyNewsletter {
            slice_type
          }
        }
      }
    }
  }
`

const Page = ({ data }) => {
  const doc = data.prismicPage

  if (!doc) return null

  return (
    <Layout aboveImage={!!doc.data.hero_image.gatsbyImageData}>
      {doc.data.hero_image.gatsbyImageData && (
        <Image
          sx={{
            variant: 'overlay',
            height: [400, 600],
          }}
          image={doc.data.hero_image.gatsbyImageData}
          fullWidth
        />
      )}
      <SEO
        title={doc.data.meta_title || doc.data.title.text}
        description={doc.data.meta_description}
        og={{
          image: doc.data.og_image.url,
          alt: doc.data.og_image.alt,
        }}
      />
      <Section fluid sx={{ px: 4 }}>
        <Themed.h2
          as="h1"
          sx={{ mt: 5, mx: 'auto', maxWidth: '25ch', textAlign: 'center' }}
          dangerouslySetInnerHTML={{ __html: doc.data.title.text }}
        />
      </Section>
      <SlicesWrapper slices={doc.data.body} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object,
}

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
